import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from 'react-router-dom';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/Loader/loader';

// const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const Page404 = lazy(() => import('@iso/containers/Pages/404/404'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/Home/Home')),
  },
  {
    path: PUBLIC_ROUTE.PROTOTYPING,
    component: lazy(() =>
      import('@iso/containers/Pages/Prototyping/Prototyping')
    ),
  },
  {
    path: PUBLIC_ROUTE.ABOUTUS,
    component: lazy(() => import('@iso/containers/Pages/AboutUs/AboutUs')),
  },
  {
    path: PUBLIC_ROUTE.SERVICES,
    component: lazy(() => import('@iso/containers/Pages/Services/Services')),
  },
  {
    path: PUBLIC_ROUTE.WORK,
    component: lazy(() => import('@iso/containers/Pages/Work/Work')),
  },
  {
    path: PUBLIC_ROUTE.CONTACTUS,

    component: lazy(() => import('@iso/containers/Pages/Contact/Contact')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,

    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
];

// function PrivateRoute({ children, ...rest }) {
//     let location = useLocation();
//     const isLoggedIn = useSelector(state => state.Auth.idToken);
//     if (isLoggedIn) return <Route {...rest}>{children}</Route>;
//     return (
//         <Redirect
//             to={{
//                 pathname: '/signin',
//                 state: { from: location },
//             }}
//         />
//     );
// }

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <div
          style={{
            width: '100%',
            height: '100%',
            // maxWidth: 1920,
            // marginLeft: 'auto',
            // marginRight: 'auto',
          }}
        >
          <Router>
            <Switch>
              {publicRoutes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact}>
                  <route.component />
                </Route>
              ))}
              {/* <PrivateRoute path="/dashboard">
                            <Dashboard />
                        </PrivateRoute> */}
              <Route component={Page404} />
            </Switch>
          </Router>
        </div>
      </Suspense>
    </ErrorBoundary>
  );
}
