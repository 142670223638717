import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Routes from './router';

export default function App() {
  const darkModes = localStorage.getItem('darkMode');
  const [open, setDarkModes] = React.useState(localStorage.getItem('darkMode'));
  const eventListenerFun = (e) => {
    console.log('localStorage', localStorage.getItem('darkMode'));
    setDarkModes(localStorage.getItem('darkMode'));
    // SyncWithLocalStorage({ ...localStorage }); //<----spread it which will cause refrence to change
  };

  React.useEffect(() => {
    window.addEventListener('storage', eventListenerFun);

    return () => window.removeEventListener('storage', eventListenerFun);
  }, []);

  return (
    <div style={{ backgroundColor: open == 'true' ? '#fff' : '#20242A' }}>
      <Routes />
    </div>
  );
}
