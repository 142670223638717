import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { amber, deepPurple, grey } from '@material-ui/core/colors';

const breakpoints = createBreakpoints({});

export const fonts = {
  prata: '"Prata", "Suranna"',
  muli: 'Muli, "Roundo Light"',
  montserrat: '"Montserrat", sans-serif',
  montserratBold: '"Montserrat", sans-serif',
};

const Montserrat = {
  fontFamily: fonts.montserrat,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: "url('/public/Muli-Regular.ttf') format('ttf')",
};

const Muli = {
  fontFamily: fonts.muli,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src:
    "url('/public/public/assets/fonts/Muli-Regular.ttf') format('ttf'), url('/public/assets/fonts/Muli-Regular.woff') format('woff'), url('/public/assets/fonts/Muli-Regular.ttf') format('opentype')",
};

const Prata = {
  fontFamily: fonts.prata,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src:
    "url('/public/assets/fonts/Prata-Regular.ttf') format('ttf'), url('/public/assets/fonts/Prata-Regular.woff') format('woff'), url('/public/assets/fonts/Prata-Regular.ttf') format('opentype'), url('/public/assets/fonts/Prata-Regular.eot') format('eot')",
};

export const color = {
  primary: '#FF0000',
  secondary: '#264653',
  white: '#ffff',
  darkGreen: '#264653',
  black: '#373F45',
  lightOrange: '#F4A261',
  disable: '#00000061',
};

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: `${color.primary}`,
    },
    secondary: {
      main: `${color.secondary}`,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#F3F6F9',
    },
  },
  typography: {
    fontFamily: fonts.montserrat,
    letterSpacing: 14,
  },
  overrides: {
    MuiGrid: {
      container: {
        maxWidth: 1100,
        margin: '0px auto',
      },
    },
    MuiAvatar: {
      img: {
        objectFit: 'contain',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '40px',
      },
    },
    MuiButton: {
      // startIcon: {
      //   marginRight: 0,
      // },
      /* Styles applied to the startIcon element if supplied. */
      startIcon: {
        marginRight: 0,
        '& > svg': {
          marginRight: 5,
        },
      },
      /* Styles applied to the endIcon element if supplied. */
      endIcon: {
        display: 'inherit',
        marginRight: -4,
        marginLeft: 8,
        '&$iconSizeSmall': {
          marginRight: -2,
        },
      },
      containedPrimary: {
        boxShadow: 'none',
        border: '1px solid',
        borderColor: '#ffffff',
        color: `${color.white}`,
        fontFamily: fonts.muli,
        '&:hover': {
          backgroundColor: '#FFF !important',
          boxShadow: 'none',
          color: `${color.primary}`,
          borderColor: `${color.primary}`,
          border: '1px solid',
        },
      },
      containedSecondary: {
        boxShadow: 'none',
        border: '1px solid',
        borderColor: `${color.secondary}`,
        color: `${color.white}`,
        fontFamily: fonts.muli,
        '&:hover': {
          backgroundColor: '#FFF !important',
          boxShadow: 'none',
          color: `${color.secondary}`,
          borderColor: `${color.secondary} `,
          border: '1px solid',
        },
      },
      outlinedPrimary: {
        boxShadow: 'none',
        color: `${color.primary}`,
        fontFamily: fonts.muli,
        border: '1px solid',
        borderColor: `${color.primary}`,
        backgroundColor: `${color.white} !important`,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: `${color.primary} !important`,
          color: `${color.white}`,
          borderColor: `${color.primary}`,
          border: '1px solid',
        },
      },
      outlinedSecondary: {
        boxShadow: 'none',
        color: `${color.secondary}`,
        fontFamily: fonts.muli,
        border: '1px solid',
        borderColor: `${color.secondary}`,
        backgroundColor: `${color.white} !important`,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: `${color.secondary} !important`,
          color: `${color.white}`,
          borderColor: `${color.secondary}`,
          border: '1px solid',
        },
      },
      textPrimary: {
        boxShadow: 'none',
        color: '#373F45',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: 'transparent',
          color: `${color.primary} !important`,
        },
      },
    },
    MuiTypography: {
      root: {
        wordBreak: 'break-word',
      },
      h1: {
        color: color.darkGreen,
        fontFamily: fonts.prata,
        fontWeight: 'normal',
        fontSize: '45px',
        [breakpoints.down('xs')]: {
          fontSize: '35px',
          textAlign: 'center',
        },
      },
      h2: {
        color: '#264653',
        fontFamily: fonts.prata,
        fontSize: '34px',
        lineHeight: '1.2em',
        letterSpacing: '1px',
        fontWeight: 'normal',
        '@media (max-width:768px)': {
          fontSize: '26px',
          lineHeight: '1.2em',
        },
        '@media (max-width: 425px)': {
          fontSize: '20px',
          textAlign: 'center',
          lineHeight: '1.4em',
        },
      },
      h3: {
        color: '#264653',
        fontFamily: fonts.prata,
        fontSize: '26px',
        lineHeight: '1.2em',
        '@media (max-width:768px)': {
          fontSize: '22px',
          lineHeight: '1.2em',
        },
        '@media (max-width: 425px)': {
          fontSize: '18px',
          // textAlign: 'left',
          lineHeight: '1.4em',
        },
      },
      h4: {
        color: '#264653',
        fontFamily: fonts.prata,
        fontSize: '22px',
        '@media (max-width: 425px)': {
          fontSize: '20px',
        },
      },
      h5: {
        fontWeight: '700',
      },
      h6: {
        fontSize: '1.5rem',
        fontWeight: '700',
      },
      subtitle1: {
        color: '#264653',
        margin: '1rem 0.2rem',
        fontFamily: fonts.muli,
        fontStyle: 'normal',
        fontSize: '1.2rem', // 16 pt
        lineHeight: '1.2em',
        '@media (max-width: 425px)': {
          fontSize: '1rem',
        },
      },
      subtitle2: {
        color: '#373F45',
        margin: '1rem 0.2rem',
        fontFamily: fonts.muli,
        fontStyle: 'normal',
        fontSize: '1.2rem', // 16 pt#373F45
        lineHeight: '1.5em',
        '@media (max-width: 425px)': {
          fontSize: '1rem',
        },
      },
      body1: {
        color: color.black,
        '@media (max-width: 425px)': {
          fontSize: '0.9rem',
        },
      },
      body2: {
        color: color.black,
        '@media (max-width: 425px)': {
          fontSize: '0.8rem',
        },
      },
    },
    MuiDivider: {
      root: {
        marginBottom: '2rem',
        [breakpoints.down('sm')]: {
          marginBottom: '1rem',
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        boxShadow: 'none',
        '&:before': {
          content: 'unset',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [Muli, Prata],
      },
    },
    MuiStepConnector: {
      lineHorizontal: {
        borderTopWidth: '2.3px !important',
      },
    },
    MuiStepLabel: {
      alternativeLabel: {
        '@media (max-width: 425px)': {
          fontSize: 12,
        },
        '@media (max-width: 375px)': {
          fontSize: 10,
        },
      },
    },
    MuiOutlinedInput: {
      // input: {
      //     padding: '12px 14px',
      //     height: 24,
      //     fontSize: 14,
      //     color: '#ffffff'
      // },
      // notchedOutline: {
      //     borderColor: 'red !important',
      //     borderWidth: '0px !important',
      // },
      // inputMultiline: {
      //     lineHeight: 'initial',
      // },
      // marginDense: {
      //     margin: 0,
      // },
      // root: {
      //     paddingRight: '0px',
      //     width: '100%',
      //     background: '#FFFFFF',
      //     fontSize: 12,
      //     minHeight: 45,
      // },
    },
    MuiSelect: {
      root: {
        display: 'flex',
        alignItems: 'center',
      },
      icon: {
        color: color.primary,
        fontSize: 30,
        top: '15%',
      },
    },
    MuiFormControl: {
      fullWidth: {
        width: '100%',
      },
      marginDense: {
        marginTop: 0,
        marginBottom: 0,
        backgroundColor: '#FFFF',
        width: '100%',
      },
    },
    MuiPagination: {
      ul: {
        justifyContent: 'flex-end',
      },
    },
    MuiChip: {
      root: {
        borderRadius: '5px',
        color: '#FFF',
        backgroundColor: color.primary,
      },
      deleteIcon: {
        color: '#FFF',
        '&:hover': {
          color: '#FFF',
        },
      },
    },
    MuiAutocomplete: {
      option: {
        '&:hover': {
          backgroundColor: '#E4F9F2',
        },
      },
      clearIndicator: {
        padding: '0px important',
        height: 'fit-content',
      },
      endAdornment: {
        display: 'flex',
        height: '100%',
      },
    },
    // PrivateNotchedOutline: {
    //   root: {
    //     borderWidth: 0,
    //     borderRadius: 5,
    //   },
    // },
    MuiTabs: {
      vertical: {},
      indicator: {
        left: '0',
        width: '5px',
      },
    },
    MuiTab: {
      root: {
        backgroundColor: '#FAFAFB !important',
        margin: '2px 0px',
        // minWidth: '600px !important',
      },
      labelIcon: {
        minHeight: '50px',
      },
      wrapper: {
        width: '100%',
        flexDirection: 'row !important',
        textTransform: 'capitalize !important',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
    },
    MuiLinearProgress: {
      root: {
        height: '28px',
        borderRadius: '6px',
      },
      colorPrimary: {
        backgroundColor: '#FFF',
      },
    },
    MuiFormControlLabel: {
      label: {
        color: '#373F45 !important',
      },
      labelPlacementStart: {
        marginLeft: '8px !important',
      },
    },
    MuiRating: {
      root: {
        fontSize: 'inherit !important',
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#E4F9F2',
        },
      },
    },
    MuiPaper: {
      elevation8: {
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 8px 0px !important',
      },
    },
    MuiAppBar: {
      positionFixed: {
        zIndex: '1350',
      },
    },
    MuiBreadcrumbs: {
      separator: {
        '@media (max-width: 425px)': {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '20px 24px 16px',
      },
    },
    MuiAccordionSummary: {
      root: {
        minHeight: '48px !important',
      },
      content: {
        margin: '0px !important',
      },
    },
    MuiLink: {
      root: {},
      underlineNone: {
        textDecoration: 'none',
      },
      /* Styles applied to the root element if `underline="hover"`. */
      underlineHover: {
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'none',
        },
      },
      /* Styles applied to the root element if `underline="always"`. */
      underlineAlways: {
        textDecoration: 'none',
      },
    },
  },
});

theme.overrides = {
  /* Button overrides not included to make this easier to read */
  MuiInputLabel: {
    disableUnderline: true, // <---------
    root: {
      textTransform: 'uppercase',
      fontSize: '1.5rem',
      '&:focused': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        outline: `1px solid ${theme.palette.primary.main}`,
      },
    },
    filled: {
      transform: 'translate(12px, 10px) scale(0.75)',
      '&$marginDense': {
        transform: 'translate(12px, 7px) scale(0.75)',
      },
    },
    outline: {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  MuiInput: {
    disableUnderline: true, // <---------
    root: {
      top: theme.spacing(2),
      borderBottom: `1px solid ${grey[500]}`,
      outline: `1px solid transparent`,
      padding: theme.spacing(1),
      '&:focused': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        outline: `1px solid ${theme.palette.primary.main}`,
      },
    },
    // we don't need `focused: {}` with overrides
  },
  MuiInputBase: {
    disableUnderline: true, // <---------
    root: {
      borderBottom: '1px solid #707070',
      '&:focused': {
        border: `0px solid ${theme.palette.primary.main}`,
        outline: `1px solid ${theme.palette.primary.main}`,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderBottom: `1px solid ${grey[500]}`,
      },
    },
  },
  MuiTextField: {
    root: {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  },
  MuiInputAdornment: {
    root: {
      color: '#FFFFFF !important',
    },
  },
};

export const htmlContent = makeStyles({
  // Styles to Content Written by user from Editor
  style: {
    '& *': {
      backgroundColor: 'transparent !important',
      position: 'static !important',
      '@media (max-width: 1024px)': {
        padding: '0 0 0 16px',
      },
      '@media (max-width: 768px)': {
        padding: '0 0 0 0',
      },
    },
    '& div': {
      width: '100% !important',
      margin: '0px !important',
      padding: '0px !important',
    },
    '& img': {
      borderRadius: 5,
    },
    '& p,span': {
      color: `${color.black} !important`,
      fontSize: '16px !important',
      fontFamily: `${fonts.muli} !important`,
      '@media (max-width: 425px)': {
        fontSize: '0.9rem !important',
      },
      lineHeight: '1.8rem !important',
      [theme.breakpoints.down('sm')]: {
        lineHeight: '1.6rem !important',
      },
      [theme.breakpoints.down('xs')]: {
        lineHeight: '1.6rem !important',
      },
    },
    '& h1,h2,h3,h4': {
      margin: '1.5rem 0px 1.5rem 0px !important',
      lineHeight: 'initial',
      color: '#264653 !important',
      fontFamily: `${fonts.prata} !important`,
      fontSize: '22px !important',
      fontWeight: '400 !important',
      '@media (max-width: 425px)': {
        fontSize: '18px !important',
        margin: '0.5rem 0px 0.5rem 0px !important',
        lineHeight: '1.6rem !important',
      },
    },
    '& a': {
      color: `${color.primary} !important`,
      '&:hover': {
        color: `${color.primary}`,
        borderColor: `${color.primary}`,
        borderBottom: '1px solid',
      },
    },
    '& ul': {
      listStyleType: 'none  !important',
      listStylePosition: 'outside  !important',
      '& > li': {
        color: `${color.black} !important`,
        fontSize: '16px !important',
        fontFamily: `${fonts.muli} !important`,
        '@media (max-width: 425px)': {
          fontSize: '0.9rem !important',
          textIndent: '-26px',
          paddingLeft: '26px',
        },
        lineHeight: '1.8rem !important',
        [theme.breakpoints.down('sm')]: {
          lineHeight: '1.6rem !important',
        },
        [theme.breakpoints.down('xs')]: {
          lineHeight: '1.6rem !important',
        },
        textIndent: '-30px',
        paddingLeft: '30px',
        [theme.breakpoints.down('sm')]: {
          textIndent: '-30px',
          paddingLeft: '30px',
        },
        '&:before': {
          content: '"•"',
          color: color.primary,
          marginRight: '16px',
          fontSize: '26px',
          [theme.breakpoints.down('sm')]: {
            marginRight: '16px',
          },
          '@media (max-width: 425px)': {
            marginRight: '16px',
            fontSize: '20px',
          },
        },
      },
    },
    '& ol': {
      '& > li': {
        fontSize: '1rem',
        '@media (max-width: 425px)': {
          // fontSize: '0.9rem',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 1rem !important',
    },
    maxWidth: '100% !important',
    wordBreak: 'break-word',
    // whiteSpace: 'pre-line',
  },
});

export default theme;
