export const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  FORGET_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/resetpassword',
  REVERIFY: '/reverify',
  PAGE_404: '/404',
  PAGE_500: '/500',
  PROTOTYPING: '/prototyping',
  SERVICES: '/services',
  WORK: '/work',
  CONTACTUS: '/contact-us',
  ABOUTUS: '/about-us',
  // AUTH0_CALLBACK: '/auth0loginCallback',
};

export const PRIVATE_ROUTE = {
  // DASHBOARD: '/',
};
